@use '@angular/material' as mat;
@mixin ea-question-result($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-question-result{

    mat-icon{
      width: 30px;
      height: 30px;
    }

    .question-result-icon-group{

      mat-icon{
        color: mat.get-color-from-palette($accent, 800);
      }

    }

    a{
      color: mat.get-color-from-palette($accent, 800) !important;
    }

    mat-divider{
      border-top-color: mat.get-color-from-palette($accent, 800, 0.5) !important;
    }

    h2{
      font-size: 25px !important;
      line-height: 30px;
    }

    h3{
      font-size: 16px !important;
      margin-bottom: 0 !important;
    }

    p{
      font-size: 22px;

      &.multiple{
        line-height: 28px;
      }

    }

    .feedback{
      color: mat.get-color-from-palette($accent, 800);
    }

  }

}
