@use '@angular/material' as mat;
@mixin ea-transversal-search-field($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-transversal-search-field{

    @extend .d-flex;
    @extend .align-items-center;
    @extend .position-relative;

    @include chevrons-mixin(5px);

    $height: 36px;

    min-height: $height;

    @include media-breakpoint-up(sm) {
      margin-left:8rem;
      margin-right:8rem;
    }

    margin-top:-$height/2;
    margin-bottom:-$height/2;
    z-index: 2;

    ::selection {
      background: mat.get-color-from-palette($accent, 500); /* WebKit/Blink Browsers */
      color: mat.get-contrast-color-from-palette($accent, 500);
    }

    .left, .right{
      @extend .d-flex;
      @extend .flex-none;
    }

    .left{
      background-color: white;
      color: mat.get-contrast-color-from-palette($accent, 500);
    }

    .middle, .right{
      background-color: white;
      color: mat.get-color-from-palette($foreground, text);
    }

    .right{
      min-width:$height;
    }

    .middle{
      @extend .flex-fill;

      form{

        width: 100%;

        input{
          width: 100%;
          line-height: $height;
          padding: 0 $height/4;
          border: none;
        }

      }

    }

  }

}
