$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$header-height: 120px;
$header-search-height: 70px;
$header-content-height: 48px;

:root{

  --breakpoint-sm: 600px;
  --breakpoint-md: 960px;
  --breakpoint-lg: 1280px;
  --breakpoint-xl: 1920px;

  --ambiant-font: 'arquitecta';
  --condensed-font: 'Barlow Condensed', sans-serif;

  --font-size-body: 16px;

  --training-color-500: #66B538;
  --encyclopedia-color-500: #FF4D52;
  --user-color-500: #03BCFF;
  --user-color-500-contrast: white;

  --practive-color-500: #FF4D52;
  --quiz-color-500: #ED8200;
  --video-color-500: #A60A9D;
  --savoir-color-500: #8A6640;

  --color-primary-500: #0D2366;
  --color-primary-500-contrast: white;

  --module-color: #66B538;
  --diplome-color: #4A8926;

  --themes-color: #083D31;
  --themes-accent-color: #118675;

  --tutorials-color: var(--color-primary-500);
  --tutorials-accent-color: var(--user-color-500);

  --training-color: #56AA2B;
  --training-accent-color: #3C791D;

  --header-height: 120px;
  --header-search-height: 70px;
  --header-content-height: 48px;

  --item-renderer-width: 50%;
  --item-renderer-height: 240px;

  --background-background: #F4F4F4;

  @include media-breakpoint-between(sm, xl) {
    --item-renderer-width: 208px;
    --item-renderer-height: 288px;
  }

  --search-infos-bar-height: 70px;

  --color-filters-border: #E3E3E3;
  --filters-border: var(--color-filters-border) 1px solid;

  @include media-breakpoint-between(sm, xl) {
    --search-infos-bar-height: 40px;
  }

}
