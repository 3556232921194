@use '@angular/material' as mat;
@mixin ea-app-menu($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-app-menu{

    ul{
      list-style-type: none;
    }

    mat-nav-list{

      .secondary-menu, .legal-menu, .user-menu{
        @extend  .py-4;
        @extend  .pl-4;
        padding-right: 3rem;
      }

      .main-menu{

        color: mat.get-contrast-color-from-palette($accent, 500);

        .menu-item{

          @extend  .pl-4;
          @extend  .py-2;

          &:first-child{
            //@extend  .pt-4;
          }

          &:last-child{
            // @extend  .pb-4;
          }

          padding-right: 3rem;

          a{
            align-self: start;
          }
        }

      }

      .secondary-menu{
        background-color: mat.get-color-from-palette($primary);
        color: mat.get-contrast-color-from-palette($primary, 500);
      }

      .user-menu{

        background-color: var(--user-color-500);
        color: var(--user-color-500-contrast);

        li{
          @extend .mb-2;
        }

        li:last-child{
          @extend .mb-0;
        }

        .mat-badge-content{
          background-color: var(--user-color-500-contrast);
          color: var(--user-color-500);
        }

        .mat-button{
          padding-left: 0;
          padding-right: 0;
        }

      }

    }

  }

}
