@import '../diaporama/diaporama.theme';

@mixin ea-savoir($theme) {

  ea-savoir {

    @include ea-diaporama($theme);

    .rich-text-group{

      h2{
        font-size: 2rem;
      }

      h3{
        font-size: 1.75rem;
      }

      h4{
        font-size: 1.4rem;
      }

      img{

        max-width: 100%;
        object-fit: contain;
        display: flex;
        height: auto;
        width: auto;

      }

      video{
        width: auto;
        height: auto;
        max-width: 100%
      }

      .table-wrapper{

        overflow-x: auto;

        table{

          @extend .table;
          @extend .table-striped;

          @include media-breakpoint-down(sm) {

            width: 800px;

            td{
              max-width: 300px;
            }

          }

          b{
            font-weight: normal;
          }

        }

      }

    }

  }

}
