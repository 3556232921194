@use '@angular/material' as mat;




@mixin ea-themes($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-themes {

    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-fill;

    max-width: 100%;

    .example-tree-invisible {
      display: none;
    }

    .example-tree ul,
    .example-tree li {
      margin-top: 0;
      margin-bottom: 0;
      list-style-type: none;
    }

    .mat-nested-tree-node {

      &.active {
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-contrast-color-from-palette($accent, 500);

        a{
          font-weight: bold;
        }

      }

    }

    .mat-tree-node {

      @extend .text-uppercase;
      color: mat.get-color-from-palette($accent, 500);
      min-height: 40px;
      border-bottom: 1px solid rgba(black, 0.1);

      &.active {
        background-color: mat.get-color-from-palette($accent, 500);
        color: mat.get-contrast-color-from-palette($accent, 500);

        a{
          font-weight: bold;
        }

      }

    }

    mat-sidenav{

      width: 225px !important;

      @include media-breakpoint-up(sm) {
        width: 325px !important;
      }
    }

    .side-nav-content{
    }

  }

}
