@use '@angular/material' as mat;
@mixin ea-quiz-session($theme){

  ea-quiz-session{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @include si-ffa-background();

    @extend .d-flex;
    @extend .flex-fill;
    @extend .flex-column;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .pb-4;

    // overflow: hidden;

    .edit-button{

      width: 34px;
      height: 34px;
      line-height: 34px;

      .mat-button-wrapper{
        padding: 0px;
      }

    }

    .session-stage-group{

      width: 100%;
      max-width: 1024px;

      ea-quiz{

        display: flex;
        flex: 1 1 auto;
        background-color: mat.get-color-from-palette($background, card);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);

        >div{

          display: flex;
          flex: 1 1 auto;

          >div{
            display: flex;
            flex: 1 1 auto;
          }

        }

      }

    }

  }

}
