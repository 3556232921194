@use '@angular/material' as mat;
@mixin ea-encyclopedia-search($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-encyclopedia-search{

    @include routed-container(true);

    @extend .overflow-hidden;

    //@extend .row;

    .content-type-select{

      .mat-form-field-appearance-legacy .mat-form-field-label {
        color: mat.get-contrast-color-from-palette($accent, 500) !important;
      }

      .mat-select-placeholder{
        color: mat.get-contrast-color-from-palette($accent, 500) !important;
      }

      .mat-select-arrow{
        color: mat.get-contrast-color-from-palette($accent, 500) !important;
      }

      label.mat-form-field-label{
        color: mat.get-contrast-color-from-palette($accent, 500) !important;
      }

      .mat-select-value-text{
        color: mat.get-contrast-color-from-palette($accent, 500) !important;
        text-transform: uppercase;
        font-weight: 700;
      }

    }

    .mat-form-field-underline{
      display: none !important;
    }

    .mat-form-field-label-wrapper{

      label{

        color: mat.get-contrast-color-from-palette($accent, 500) !important;
        text-transform: uppercase;
        font-weight: 700;

        &.mat-form-field-label{
          color: mat.get-contrast-color-from-palette($accent, 500) !important;
        }

      }

    }

    .promo-group{

      >.content-group{

        ea-subscription-bloc, .title{
          max-width: 300px;
        }
      }
    }

  }

}
