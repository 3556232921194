@use '@angular/material' as mat;
$user-palette-primary: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: var(--user-color-500),
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #FF4D52,
  A200: #000000,
  A400: #000000,
  A700: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: var(--user-color-500-contrast),
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: white,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$user-palette-accent: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #0D2366,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: white,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$abonnement-palette-primary: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #03BCFF,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: white,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$abonnement-palette-accent: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #000000,
  400: #000000,
  500: #0D2366,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #000000,
  A200: #000000,
  A400: #000000,
  A700: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: white,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$app-user-primary: mat.define-palette($user-palette-primary);
$app-user-accent: mat.define-palette($user-palette-accent);

$user-theme: mat.define-light-theme($app-user-primary, $app-user-accent, $app-warn);

$app-abonnement-primary: mat.define-palette($user-palette-primary);
$app-abonnement-accent: mat.define-palette($user-palette-accent);

$abonnement-theme: mat.define-light-theme($app-abonnement-primary, $app-abonnement-accent, $app-warn);

@import "../../app/components/dialog/dialog-theme";
@import "../../app/pwa/pwa-install/pwa-install.theme";

@mixin user-theme{

  @include mat.all-component-colors($user-theme);

  @include ea-dialog($user-theme);
  @include vertical-menu($user-theme);

  @include components-theme($user-theme);

  @include ea-pwa-install($user-theme);

  $accent: map-get($user-theme, accent);
  $primary: map-get($user-theme, primary);
  $background: map-get($user-theme, background);

  .mat-button[color="primary"] {
    color: mat.get-color-from-palette($primary, 500) !important;
  }

  .mat-raised-button[color="primary"] {
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .mat-raised-button[color="accent"] {
    color: mat.get-contrast-color-from-palette($accent, 500) !important;
  }

  .mat-card{
    color: mat.get-color-from-palette($accent, 500) !important;
  }

  &.mat-primary-group, .mat-primary-group{
    background-color: mat.get-color-from-palette($primary, 500) !important;
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

}

.user-theme {

  @include user-theme();

}

.user-account-window{

  @extend .user-window;

  @include media-breakpoint-up(sm){

    @media (min-height: 500px){
      //min-height: 490px !important;
      //max-height: 490px !important;
    }

  }

}

.domains-window{

  max-height: 100vh;
  max-width: 100vw!important;

  .mat-dialog-container{
    overflow: hidden!important;

    ea-preference-window{

      @include media-breakpoint-only(xs) {
        // position: absolute;
        // inset: 0;
      }

    }

  }

}

.user-window{

  width: 100%;
  max-width: none !important;

  min-height: 100%;
  max-height: 100%;

  @include media-breakpoint-between(md, xl) {
    width: 960px;
    max-width: 100vw;
  }

  @include media-breakpoint-up(sm){

    @media (max-height: 500px){
      min-height: 100%;
      max-height: 100%;
    }

    @media (min-height: 500px){
      min-height: 300px;
      max-height: 500px;
    }

  }



  .mat-dialog-container{
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-fill;
    height:auto !important;

    ea-user-window{

      @extend .d-flex;
      @extend .flex-column;
      @extend .flex-fill;

      ea-dialog{

        @extend .d-flex;
        @extend .flex-column;
        @extend .flex-fill;

        .mat-dialog-content{

          max-height: none !important;

          @include media-breakpoint-only(xs) {
            max-height: none !important;
          }

          @include media-breakpoint-only(xs) {
            padding: 0px !important;
          }

          .template-group{

            >*{
              @extend .flex-fill;
            }

          }

        }

      }

    }

  }

}
