/* ==|====================
   Layout/Simple Page
   ======================= */

.space-after:after {
  content: '\00a0';
}

.space-before:before {
  content: '\00a0';
}

.two-points-after:after {
  content: '\00a0:\00a0';
}

.hyphen-after:after {
  content: '\00a0-\00a0';
}

.slash-after:after {
  content: '\00a0/\00a0';
}

.slash-before:before {
  content: '\00a0/\00a0';
}

.pipe-before:before {
  content: '\00a0|\00a0';
}

.pipe-after:after {
  content: '\00a0|\00a0';
}
