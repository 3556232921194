@use '@angular/material' as mat;
@mixin ea-mini-list-search($theme){

  ea-mini-list-search{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    background-color: mat.get-color-from-palette($background, background);

    .xs-search-result-link{
      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-contrast-color-from-palette($accent, 500);
    }

    width: 85vw;

    &.sm, &.md, &.lg{
      width: calc((var(--item-renderer-width)) + 15px);
      --mini-list-column-count: 1;
    }

    &.xl{
      width: calc((var(--item-renderer-width)*2) + 15px);
      --mini-list-column-count: 2;
    }

    &.xxl{
      width: calc((var(--item-renderer-width)*3) + 15px);
      --mini-list-column-count: 3;
    }


    virtual-scroller{
      top: 0 !important;
    }

    .item-group{

      @include item-renderer();

    }

  }

}
