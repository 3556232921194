@mixin ea-answers($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-answers{

    @extend .d-flex;
    @extend .font-size-subheading-2;

    .mat-radio-checked{
      @extend .font-weight-bold;
    }

    .mat-ripple{
      @extend .d-none;
    }

    mat-radio-group {

      &.images{

        @include media-breakpoint-only(xs) {
          flex: 0 0 100% !important;
        }

      }

    }

    .btn-slide-control {

      @include media-breakpoint-only(xs) {
        flex: 0 0 50%;
      }

    }

  }

}
