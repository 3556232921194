@use '@angular/material' as mat;
@mixin ea-si-ffa-landing-page($theme){

  ea-si-ffa-landing-page{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @include si-ffa-background();
    @extend .flex-fill;

    ea-ssi-ffa-user-final-form{

      min-width: 100%;
      max-width: 100%;

      @include media-breakpoint-between(md, xl) {
        min-width: 500px;
        max-width: 500px;
      }

      background-color: mat.get-color-from-palette($background, card);
    }

  }

}
