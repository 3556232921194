@mixin loading($size){

  @keyframes rotating
  {
    from
    {
      transform: rotate(0deg);
    }
    to
    {
      transform: rotate(360deg);
    }
  }

  @extend .d-flex;

  padding: 0.5em;
  animation: rotating 1.5s linear infinite;
  transform-origin: center;
  //transform: translate3d(-50%, -50%, 0);

  mat-icon{
    width: $size;
    height: $size;
    line-height: $size;
    position: relative;

    svg{
      position: absolute;
      top:0px;
    }

  }

}
