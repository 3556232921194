@use '@angular/material' as mat;
@mixin ea-line-practice-renderer($theme) {

  ea-line-practice-renderer {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    $sizeIcon: 48px;

    @extend .d-flex;
    @extend .flex-column;

    @extend .p-2;

    border: 1px mat.get-color-from-palette($foreground, divider) solid;

    .infos-group {

      >div{
        &.inactive{
          opacity: 0.5;
        }
      }

      mil-image-base{

        img{
          object-fit: cover;
        }

      }

      .play-button{

        width: 100%;
        height: 100%;
        background-color: transparent;

        mat-icon {
          color: mat.get-color-from-palette($background, background);
          z-index: 1;
          top: calc(50% - #{$sizeIcon/2});
          left: calc(50% - #{$sizeIcon/2});
          width: $sizeIcon;
          height: $sizeIcon;
        }

      }

      mil-image-base, .play-button{
        max-height: 70px;
      }

      .content-index{
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        background-color: mat.get-color-from-palette($primary, 500) !important;;
        color: mat.get-contrast-color-from-palette($primary, 500) !important;
      }

    }

    .video-group {

      transition: flex-basis 400ms ease-in-out;
      flex-basis: 0px;
      overflow-y: hidden;
      flex-shrink: 0;

      &.expanded {

        &.no-video{

          @include media-breakpoint-only(xs) {
            flex-basis: 135px;
          }

          @include media-breakpoint-up(xs) {
            flex-basis: 120px;
          }

        }

        @include media-breakpoint-only(xs) {
          flex-basis: 320px;
        }

        @include media-breakpoint-up(xs) {
          flex-basis: 520px;
        }

      }

    }

    .link-button {
      @include ffa-button();
      background-color: mat.get-color-from-palette($primary, 500) !important;;
      color: mat.get-contrast-color-from-palette($primary, 500) !important;
    }


    /*@media(hover: hover) {

      a {

        .link-button {
          transition: opacity .25s ease-in;
          opacity: 0;
        }

        transition: background-color .25s ease-in, color .25s ease-in;

        &:hover {

          background-color: mat.get-color-from-palette($primary, 500);
          color: mat.get-contrast-color-from-palette($primary, 500) !important;

          .link-button {
            opacity: 1;
          }

        }

      }

    }*/

  }

}
