@mixin aspect-ratio($width, $height) {

  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

}

.square-ratio{

  @include aspect-ratio(1, 1);

}

.four-three-ratio{

  @include aspect-ratio(4, 3);

}

.sixteen-nine-ratio{

  @include aspect-ratio(16, 9);

}

.dv-pal-ratio{

  @include aspect-ratio(788, 576);

}
