@use '@angular/material' as mat;
@mixin ea-quiz-gauge($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-quiz-gauge{

    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .ambiant-font;
    @extend .text-uppercase;
    @extend .font-weight-bold;
    @extend .pb-1;
    @extend .position-relative;

    color: mat.get-contrast-color-from-palette($accent, 500);
    background-color: mat.get-color-from-palette($foreground, text, 0.3);

    .activable{

      &.active{
        background-color: mat.get-color-from-palette($accent, 500);
      }

    }

    .full-screen-button{
      right: 3px;
      top: -3px;
    }

  }


}
