@mixin routed-container($fill:false){

  //@include make-row();
  @extend .d-flex;

  max-width: 100%;

  @if $fill == true {
    @extend .flex-fill;
    //min-height: fit-content;
    @include media-breakpoint-between(sm, xl) {
      @include absolute-container();
    }
  } @else {
    //@extend .align-self-start;
    min-height: 100%;
  }

  @extend .flex-column;
  @extend .flex-nowrap;
}
