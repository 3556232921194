@use '@angular/material' as mat;
@mixin ea-mat-tabs-group($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  min-width: 100%;

  .mat-tab-header {

    @extend .uppercase;

    background-color: mat.get-color-from-palette($accent, 500);
    color: mat.get-contrast-color-from-palette($primary, 500);
    border-bottom: none !important;

    .mat-tab-labels {

      @extend .justify-content-center;

      .mat-tab-label, .mat-tab-link {

        @extend .ambiant-font;
        @extend .font-weight-bold;
        @extend .flex-fill;
        @extend .font-size-title;

        color: mat.get-contrast-color-from-palette($accent, 500) !important;

        &.cdk-focused {
          background-color: transparent !important;
        }
      }

    }

    .mat-ink-bar {
      background-image: url('../../assets/components/tabs/mat-ink-bar-background.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent !important;
      height: 8px;
    }

  }

  .mat-tab-body-wrapper {
    background-color: mat.get-color-from-palette($background, background);
  }

}

@mixin ea-formation-mat-tabs-group($theme) {

  @include ea-mat-tabs-group($theme);

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-tab-header {

    .mat-tab-labels {

      .mat-tab-label, .mat-tab-link {
        @extend .font-size-subheading-2;
        font-weight: normal !important;
        font-family: mat.font-family($app-typography) !important;
      }

    }

  }

  .mat-tab-body {
    @extend .p-3;
    background-color: mat.get-color-from-palette($accent, 200);
    color: mat.get-color-from-palette($accent, 700);
  }

  .mat-ink-bar {
    //https://codepen.io/sosuke/pen/Pjoqqp
    filter: brightness(0) saturate(100%) invert(98%) sepia(2%) saturate(2456%) hue-rotate(41deg) brightness(89%) contrast(104%);

  }
}
