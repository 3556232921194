@use '@angular/material' as mat;
@mixin ea-user-login($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $typoConfig: mat.define-typography-config();

  ea-user-login {

    @extend .position-relative;

    form {

      @extend .d-flex;
      @extend .flex-column;

      .forgot-password-button {
        @extend .align-self-center;
        @extend .d-flex;
      }

    }

    mil-forgot-password {

      form {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        justify-content: center;

        mat-form-field {
          flex: 0 0 100%;
        }

        button {
          flex: 0 0 100px;
          @extend .mx-3;
        }
      }

    }

    .alert-group {
      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-contrast-color-from-palette($accent, 500);
    }

  }

}
