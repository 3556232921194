@mixin ea-universe-navigator($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-universe-navigator{

    min-width: 100%;
    max-width: 100%;

    mat-tab-group{
      @include ea-mat-tabs-group($theme);
      max-width: 100%;

      .mat-tab-label{

        max-width: 150px;

      }

    }

  }

}
