@use '@angular/material' as mat;
@mixin ea-face-a-face($theme){

  ea-face-a-face{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .mat-badge-content{
      background-color: transparent !important;
      color: mat.get-color-from-palette($accent) !important;
      top: -5px !important;
      right: -18px !important;
    }

    .accordion{

      @include media-breakpoint-only(xs) {

        margin-left: -24px;
        margin-right: -24px;

        mat-card{
          margin-left: -24px;
          margin-right: -24px;
        }

      }
    }
    .files-expansion-panel{

      .mat-expansion-panel-body{

        @include media-breakpoint-only(xs) {

          padding-left: 0px;
          padding-right: 0px;

        }

      }

    }

  }

}
