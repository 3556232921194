@use '@angular/material' as mat;
@mixin ea-trainee-module($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-trainee-module {

    @extend .d-flex;
    @extend .flex-column;

    background-color: mat.get-color-from-palette($background, card);

    .module-header {
      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-contrast-color-from-palette($accent, 500);
    }

    ul {
      @extend .list-style-type-none;
      @extend .p-0;
    }

    .link-group {

      &.theme{
        background-color: mat.get-color-from-palette($accent, 200);
      }

      color: mat.get-color-from-palette($accent, 700);
      margin-left:1px;
      max-width: 33%;

    }

    .link-group:first-child {
      margin-left:0px;
    }

  }

}
