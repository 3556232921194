@use '@angular/material' as mat;
@import "components/encyclopedia-bloc/encyclopedia-bloc-theme";
@import "../components/subscription-bloc/subscription-bloc-theme";
@import "components/universe-navigator/universe-navigator-theme";

@mixin ea-home($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-home{

    // @include routed-container(true);

    @include ea-subscription-bloc($theme);
    @include ea-encyclopedia-bloc($theme);

    //@include ea-universe-navigator($theme);

    min-height: fit-content;

    background-color: mat.get-color-from-palette($background, card);

    .tutorials-group{
      background-color: mat.get-color-from-palette($primary, 500);
      color: mat.get-contrast-color-from-palette($primary, 500) !important;
    }

    .poster-group{

      background-size: cover;

      &.anonymous{
        min-height:250px;
      }

      .content-group{

        max-width: 960px;

        @include media-breakpoint-between(md, xl) {
          // padding: 8rem;
        }

        color:white;
        text-align: center;

        p{
          margin: 0px;
        }

      }

    }

    .subscribe-bloc{

      background-image: url('../../assets/image/abonnez-vous.png');
      background-size: cover;
      @extend .pt-3;

    }

    .side-column{
      min-width: 340px;
      max-width: 340px;
    }

    .main-column{
      max-width: none;
      background-color: mat.get-color-from-palette($background, background);
    }

    .news-main-title{
      text-transform: uppercase;
      color: mat.get-color-from-palette($accent, 500);
      line-height: 20px;
    }

    .news-title{
    }

  }

}

@mixin ea-home-training($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-home {

    .training-group {

      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-contrast-color-from-palette($accent, 500);

      .ffa-button{
        background-color: mat.get-contrast-color-from-palette($accent, 500) !important;
        color: mat.get-color-from-palette($accent, 500) !important;
      }

    }

  }

}
