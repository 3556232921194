$ambiant-font-family:'arquitecta';

// Normal
@font-face {
  font-family: $ambiant-font-family;
  src: url('../fonts/latinotype_-_arquitectamedium-webfont.woff2') format('woff2'),
  url('../fonts/latinotype_-_arquitectamedium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

// Bold
@font-face {
  font-family: $ambiant-font-family;
  src: url('../fonts/latinotype_-_arquitectablack-webfont.woff2') format('woff2'),
  url('../fonts/latinotype_-_arquitectablack-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}
