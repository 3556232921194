@use '@angular/material' as mat;
@import '../common/content-header/content-header-theme';
@import '../common/contents-common/contents-common-theme';
@import '../common/line-practice-renderer/line-practice-renderer-theme';
@import '../components/situation/situation-theme';
@import '../components/savoir/savoir-theme';
@import '../components/seance/seance-theme';
@import '../common/connection-promo-bloc/connection-promo-bloc.theme';

@mixin ea-contents($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $typoConfig: mat.define-typography-config();

  ea-contents {

    @include routed-container(true);
    @include ea-content-header($theme);
    @include ea-contents-common($theme);
    @include ea-situation($theme);
    @include ea-seance($theme);
    @include ea-savoir($theme);
    @include ea-connection-promo-bloc($theme);

    @include media-breakpoint-up(sm) {
      @include absolute-container();
    }

    //@include ea-module($theme);
    //@include ea-diplome($theme);

    //padding-top: 2.5rem;
    //padding-bottom: 1rem;

    ea-quiz{
      min-width: 100%;
    }

    @include media-breakpoint-up(sm) {
      overflow: hidden;
    }

    ea-content-sidenav-container{
      @include media-breakpoint-up(sm) {
        overflow: hidden;
      }
    }

    .mat-drawer-container, .content-group {
      background-color: mat.get-color-from-palette($background, background);
    }

    vg-player {
      background-color: mat.get-color-from-palette($primary, 900);
    }

    .breadcrumb-group{
      max-height:8em;
      color: mat.get-contrast-color-from-palette($accent, 500);
      background-color: mat.get-color-from-palette($accent, 500);
    }

    .back-search{

      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-contrast-color-from-palette($accent, 500) !important;

      @extend .mat-elevation-z2;
      z-index: 2;

      .label-group{
        //border-left: mat.get-contrast-color-from-palette($accent, 500) 1px solid;
        line-height: 1em;
      }

    }

    .line-renderer-list{
      @include line-renderer-list($theme);
    }

    .router-outlet-group{

      .ea-fiche{
        flex-direction: column;
        flex: none;
        @extend .position-relative;
        @extend .d-flex;
        // align-items: center;
        min-height: 100%;
        //justify-content: flex-start !important;
      }

    }

  }

}
