@use '@angular/material' as mat;
@mixin search-infos-bar($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  background-color: mat.get-color-from-palette($accent, 500);
  color: mat.get-contrast-color-from-palette($accent, 500);
  min-height: var(--search-infos-bar-height);

  @include media-breakpoint-up(sm) {
    padding-top: 0;
  }

  mat-divider{
    height: var(--search-infos-bar-height);
    border-right-color: mat.get-contrast-color-from-palette($accent, 500);
  }

  &.search-context{

    padding-top: 15px;

    @include media-breakpoint-up(sm){
      padding-top: 0;
    }

  }

}
