@use '@angular/material' as mat;
@mixin ea-theme-sub-branch-renderer($theme){

  ea-theme-sub-branch-renderer{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .d-flex;
    @extend .flex-column;
    @extend .pt-2;
    @extend .pl-3;
    @extend .pr-3;
    @extend .pb-3;
    @extend .mb-3;

    background-color: mat.get-color-from-palette($background, card);
    border-top: 5px solid mat.get-color-from-palette($accent, 500);

    .title{
      color: mat.get-color-from-palette($accent, 500);
    }

  }

}
