@use '@angular/material' as mat;
@mixin ea-diplome($theme) {

  ea-diplome {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    mat-tab-group{

      @include ea-formation-mat-tabs-group($theme);

    }

    .module-list-group{
      border-top: mat.get-color-from-palette($accent) solid 3px;
    }

    @include ea-content-list($theme);

  }

}
