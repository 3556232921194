@use '@angular/material' as mat;
@mixin ea-encyclopedia-home($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-encyclopedia-home{

    @include routed-container(true);

    .editorial-group{
      color:mat.get-contrast-color-from-palette($primary, 500);
    }

    ea-promoted-contents-bloc{
      background-color: mat.get-color-from-palette($background, background);
    }

  }

}
