@use '@angular/material' as mat;
@mixin ea-practice-renderer($theme) {

  ea-practice-renderer {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .condensed-font;
    // @extend .min-height-100;

    a {

      &.active {
        opacity: 0.5;
      }

      mil-image-base {
        img {
          object-fit: cover;
        }
      }

    }

    .title-group {

      @extend .position-absolute;
      @extend .overflow-hidden;

      @extend .px-2;
      @extend .py-1;

      z-index: 1;
      bottom: -1px;
      left: 0px;
      width: 100%;
      height: 70%;

      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);

      color: mat.get-contrast-color-from-palette($primary, 500);

      .title {

      }

    }

    .title-group-full {
      color: mat.get-color-from-palette($foreground, text);
    }

    .title {

      @extend .font-weight-bold;
      @extend .condensed-font;
      @extend .m-0;

    }

    mat-chip {
      @extend .default-font;
      pointer-events: none;
      background-color: mat.get-color-from-palette($background, card, 0.8) !important;
      font-size: 11px;
      padding: 3px 9px;
      border-radius: 12px;
      min-height: 24px;
      max-height: 24px;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 1;
    }

  }

  ea-practice-renderer:after {
    content: "";
    display: block;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 71%, white 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}
