@use '@angular/material' as mat;

@include mat-ffa-typography($app-typography);

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

@import "../../app/components/components.theme";

@import './dark-theme';

@import './default-theme';

@import './filters-theme';

@import './quiz-theme';

@import './savoir-theme';

@import './session-quiz-theme';

@import './training-theme';

@import './user-theme';

@import './video-theme';

@import './trainee-theme';


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@import '../../app/core/containers/app-theme';

@import "../../app/themes/containers/term-branch/term-branch-theme";

@import "../../app/quiz-session/quiz-session/quiz-session.theme";
@import "../../app/components/si-ffa-landing-page/si-ffa-landing-page.theme";
@import "../../app/components/app-snackbar/app-snackbar.theme";

.cdk-global-scrollblock {
  @extend .overflow-hidden;
}

// font-size:112px
.font-size-display-4 {
  font-size: mat.font-size($app-typography, display-4);
}

// font-size:56px
.font-size-display-3 {
  font-size: mat.font-size($app-typography, display-3);
}

// font-size:45px
.font-size-display-2 {
  font-size: mat.font-size($app-typography, display-2);
  line-height: mat.line-height($app-typography, display-2);
}

// font-size:34px
.font-size-display-1 {
  font-size: mat.font-size($app-typography, display-1);
}

// font-size:24px
.font-size-headline {
  font-size: mat.font-size($app-typography, headline);
  line-height: mat.line-height($app-typography, headline);
}

// font-size:20px
.font-size-title {
  font-size: mat.font-size($app-typography, title);
  line-height: mat.line-height($app-typography, title);
}

// font-size:16px
.font-size-subheading-2 {
  font-size: mat.font-size($app-typography, subheading-2);
}

// font-size:15px
.font-size-subheading-1 {
  font-size: mat.font-size($app-typography, subheading-1);
}

// font-size:14px
.font-size-body-2 {
  font-size: mat.font-size($app-typography, body-2);
}

// font-size:14px
.font-size-body-1 {
  font-size: mat.font-size($app-typography, body-1);
}

// font-size:14px
.font-size-button {
  font-size: mat.font-size($app-typography, button);
}

// font-size:12px
.font-size-caption {
  font-size: mat.font-size($app-typography, caption);
}

// font-size:12px
.font-size-mini {
  font-size: 10px;
  line-height: 20px;
  font-weight: 400;
}


.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto;
}

.flex-none {
  flex: none;
}

.min-height-100 {
  min-height: 100%;
}

.mat-raised-button, .mat-button-toggle {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 0px !important;
}

.list-style-type-none {
  list-style-type: none;
}

.condensed-font {
  font-family: 'Barlow Condensed', sans-serif;
}

.ambiant-font {
  font-family: $ambiant-font-family !important;
}

.default-font {
  font-family: mat.font-family($app-typography) !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-hidden {
  @extend .overflow-x-hidden;
  @extend .overflow-y-hidden;
}

.overflow-y-auto {
  @extend .overflow-x-hidden;
  overflow-y: auto;
}

.overflow-y-scroll {
  @extend .overflow-x-hidden;
  overflow-y: scroll;
}

.uppercase {
  text-transform: uppercase;
}

.user-select-text {
  user-select: text;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-radio-label {
  white-space: normal !important;
}

.ffa-icon-button {
  @include icon-label-button();
}

.white-space-initial {
  white-space: initial;
}

.object-fit-cover {
  object-fit: cover;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@mixin ea-theme($theme) {

  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  @include ea-loading($theme);
  @include ea-user-login($theme);
  @include ea-training-renderer($theme);
  @include ea-quiz-session($theme);
  @include ea-si-ffa-landing-page($theme);

  //@include mat.expansion-theme($theme);

  @include ea-app-snackbar($theme);

  .ffa-snackbar {
    background-color: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);
    border-radius: 0px;
    margin-top: 64px;
  }

  .mat-primary-color {
    color: mat.get-color-from-palette($primary);
  }

  .mat-accent-color {
    color: mat.get-color-from-palette($accent);
  }

  .mat-background-card {
    background-color: mat.get-color-from-palette($background, card);
  }

  .mat-background-primary-color {
    background-color: mat.get-color-from-palette($primary);
  }

  .mat-background-accent-color {
    background-color: mat.get-color-from-palette($accent);
  }

  button {
    @extend .ambiant-font;
    text-transform: uppercase;
    font-weight: bold !important;
  }

  .mat-raised-button[color="primary"] {
    color: mat.get-contrast-color-from-palette($primary, 500) !important;
  }

  .mat-raised-button[color="accent"] {
    color: mat.get-contrast-color-from-palette($accent, 500) !important;
  }

  .mat-raised-button {
    @include ffa-button();
    box-shadow: none !important;
  }

  .mat-placeholder-required {
    display: inline-block !important;
  }

  .mat-primary {
    //color: mat.get-color-from-palette($primary);
  }

  .mat-accent {
    color: mat.get-color-from-palette($accent);
  }

}

//@include ea-theme($app-theme);
//@include ea-root($app-theme);


@import '../../app/components/list-slider/list-slider-theme';

@import '../../app/components/content-sidenav-container/content-sidenav-container-theme';


@import '../../app/faq/faq-theme';


@import '../../app/components/bookmarks/bookmarks.theme';

@include ea-content-sidenav-container($app-theme);
@include mat.all-component-themes($app-theme);

.default-theme {

  $background: map-get($app-theme, background);

  @include ea-theme($app-theme);
  @include ea-root($app-theme);

  @include ea-universe-navigator($app-theme);
  @include ea-list-slider($app-theme);
  @include ea-faq($app-theme);

  @include item-renderers($app-theme);
  @include themes($app-theme);
  @include tutorials($app-theme);

  @include ea-bookmarks($app-theme);

  .background-card {
    background-color: mat.get-color-from-palette($background, card);
  }

  .user-theme {
    @include user-theme();
  }

  .mat-menu-content {

    @extend .py-0;
    background-color: mat.get-color-from-palette(map-get($abonnement-theme, primary), 500) !important;

    .user-menu-item {

      @extend .d-flex;
      @extend .align-items-center;
      @extend .text-uppercase;
      @extend .font-weight-bold;

      color: mat.get-contrast-color-from-palette(map-get($abonnement-theme, primary), 500) !important;

      .mat-icon {
        color: mat.get-contrast-color-from-palette(map-get($abonnement-theme, primary), 500) !important;
      }

    }

  }

}

.formation-internal-theme {
  @include mat.all-component-colors($app-theme);
  @include ea-quiz($app-theme);
}

.abonnement-theme {

  @include mat.all-component-colors($abonnement-theme);

  $accent: map-get($abonnement-theme, accent);
  $primary: map-get($abonnement-theme, primary);

  .mat-primary {
    color: mat.get-color-from-palette($primary);
  }

}

@import '../../app/contents/containers/contents-theme';
@import "../../app/home/components/training-bloc/training-bloc-theme";
@import '../../app/contents/components/face-a-face/face-a-face.component-theme';
@import '../../app/contents/components/diplome/diplome-theme';
@import '../../app/contents/components/module/module-theme';

@import '../../app/home/home-theme';

@import '../../app/trainee-area/trainee-area.theme';

@import '../../app/search/components/mini-list-search/mini-list-search.theme';

@import '../../app/components/training-tools/training-tools.theme';

@include ea-training-tools($training-theme);

@include ea-mini-list-search($training-theme);

@include ea-home-training($training-theme);

@include ea-trainee-area($training-theme);

.encyclopedia-theme {

  $accent: map-get($app-theme, accent);
  $primary: map-get($app-theme, primary);

  @include ea-search($app-theme);
  @include ea-contents($app-theme);
  @include ea-theme-sub-branch-renderer($app-theme);
  @include ea-term-branch($app-theme);
  @include ea-renderer-switcher($app-theme);
  @include ea-mini-list-search($app-theme);

  &.mat-accent-group, .mat-accent-group {
    background-color: mat.get-color-from-palette($accent, 500) !important;
    color: mat.get-contrast-color-from-palette($accent, 500) !important;
  }

  &.border-accent, .border-accent {
    border-color: mat.get-color-from-palette($accent, 500) !important;
  }

  &.search-infos-bar {
    @include search-infos-bar($app-theme);
  }

}

.training-theme {

  @include mat.all-component-colors($training-theme);

  @include ea-theme($training-theme);
  @include ea-contents($training-theme);
  @include ea-root($training-theme);

  @include ea-diplome($training-theme);
  @include ea-module($training-theme);
  //@include ea-universe-navigator($training-theme);
  @include ea-training-bloc($training-theme);
  @include ea-training-renderer($training-theme);
  @include ea-face-a-face($training-theme);
  @include ea-training-item-link($training-theme);

  @include ea-renderer-header($training-theme);
  @include ea-renderer-switcher($training-theme);

  @include ea-universe-navigator($training-theme);

  @include ea-search($training-theme);

  $accent: map-get($training-theme, accent);
  $primary: map-get($training-theme, primary);

  .default-theme {
    @include ea-training-renderer($app-theme);
  }

  .mat-background-accent-color, &.mat-background-accent-color {
    background-color: mat.get-color-from-palette($accent);
  }

  .mat-primary, &.mat-primary {
    color: mat.get-color-from-palette($primary);
  }

  .mat-accent, &.mat-accent, .mat-accent-color, &.mat-accent-color {
    color: mat.get-color-from-palette($accent);
  }

  .condensed-font {
    font-family: 'Barlow Condensed', sans-serif;
  }

  &.mat-accent-group, .mat-accent-group {
    background-color: mat.get-color-from-palette($accent, 500) !important;
    color: mat.get-contrast-color-from-palette($accent, 500) !important;
  }

  &.border-accent, .border-accent {
    border-color: mat.get-color-from-palette($accent, 500) !important;
  }

  &.search-infos-bar {

    @include search-infos-bar($training-theme);

  }

}

.trainee-theme {

  @include mat.all-component-colors($trainee-theme);
  @include ea-training-bloc($trainee-theme);

}

@import '../../app/themes/containers/theme/theme-theme';
@include ea-theme($training-theme);

@import '../../app/search/components/search-filters/search-filters-theme';

.filters-theme {

  // @include mat.all-component-colors($filters-theme);

  $accent: map-get($filters-theme, accent);
  $primary: map-get($filters-theme, primary);
  $foreground: map-get($filters-theme, foreground);
  $background: map-get($filters-theme, background);

  @include search-filters($app-theme);
  @include ea-search-filters($app-theme);

}

.quiz-theme {

  @include mat.all-component-colors($quiz-theme);

  @include mat.button-theme($quiz-button-theme);
  @include mat.radio-theme($quiz-theme);

  @include ea-quiz($quiz-theme);

  @include ea-renderer-header($quiz-theme);
  @include ea-renderer-switcher($quiz-theme);

  $accent: map-get($quiz-theme, accent);
  $primary: map-get($quiz-theme, primary);
  $foreground: map-get($quiz-theme, foreground);
  $background: map-get($quiz-theme, background);

  color: mat.get-color-from-palette($foreground, text);

  .mat-raised-button {
    color: mat.get-color-from-palette(map-get($quiz-button-theme, foreground), text) !important;
  }

  &.mat-accent-group, .mat-accent-group {
    background-color: mat.get-color-from-palette($accent, 500) !important;
    color: mat.get-contrast-color-from-palette($accent, 500) !important;
  }

  &.border-accent, .border-accent {
    border-color: mat.get-color-from-palette($accent, 500) !important;
  }

}

.session-quiz-theme {

  @include mat.all-component-colors($session-quiz-theme);

  @include mat.button-theme($session-quiz-button-theme);
  @include mat.radio-theme($session-quiz-theme);

  @include ea-quiz($session-quiz-theme);

  $accent: map-get($session-quiz-theme, accent);
  $primary: map-get($session-quiz-theme, primary);
  $foreground: map-get($session-quiz-theme, foreground);
  $background: map-get($session-quiz-theme, background);

  color: mat.get-color-from-palette($foreground, text);

  .mat-raised-button {
    color: mat.get-color-from-palette(map-get($session-quiz-button-theme, foreground), text) !important;
  }

}

.savoir-theme {

  $accent: map-get($savoir-theme, accent);

  @include mat.all-component-colors($savoir-theme);

  @include ea-renderer-header($savoir-theme);
  @include ea-renderer-switcher($savoir-theme);

  &.mat-accent-group, .mat-accent-group {
    background-color: mat.get-color-from-palette($accent, 500) !important;
    color: mat.get-contrast-color-from-palette($accent, 500) !important;
  }

  &.border-accent, .border-accent {
    border-color: mat.get-color-from-palette($accent, 500) !important;
  }

}

.video-theme {

  $accent: map-get($video-theme, accent);

  @include mat.all-component-colors($video-theme);

  @include ea-renderer-header($video-theme);
  @include ea-renderer-switcher($video-theme);

  &.mat-accent-group, .mat-accent-group {
    background-color: mat.get-color-from-palette($accent, 500) !important;
    color: mat.get-contrast-color-from-palette($accent, 500) !important;
  }

  &.border-accent, .border-accent {
    border-color: mat.get-color-from-palette($accent, 500) !important;
  }

}

.medium-icon {

  position: relative;
  width: 18px;
  height: 18px;

  > svg {
    position: absolute;
    top: 0px;
    left: 0px;
  }

}

.mat-badge-small {

  .mat-badge-content {
    font-size: 10px;
  }

}

.mat-tab-label-active {
  opacity: 1 !important;
}

.contact-group {

  .content-group {

    @extend .p-3;

    @include media-breakpoint-up(md) {
      padding: 0px;
      max-width: 600px;
    }

  }

}

.top-5 {
  top: 5px;
}

.bottom-5 {
  bottom: 5px;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.mat-button-focus-overlay {
  opacity: 0 !important;
}

.cdk-overlay-backdrop {
  background: rgb(12, 23, 82, 0.3);
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  backdrop-filter: grayscale(0.8) blur(5px);
}

.mat-chip.mat-standard-chip::after {

  background-color: transparent !important;

}
