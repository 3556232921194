@mixin line-renderer-list($theme) {

  @include media-breakpoint-only(xs) {
    margin-left: -24px;
    margin-right: -24px;

    .col-*{
      padding-right: 5px;
      padding-left: 5px;
    }
  }

}
