@import "./question-result/question-result.theme";
@mixin ea-results($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-results{

    @include ea-question-result($theme);

    @extend .d-flex;
    @extend .flex-fill;
    @extend .flex-column;

    z-index: 1;

    a{
      text-decoration: underline;
    }

    .score-title{
      font-size: 30px;

      .score{
        font-size: 50px !important;
      }

    }

    .public-result-group{
      margin-bottom: 80px;
    }

  }

}
