@use '@angular/material' as mat;
@mixin ea-renderer-switcher($theme) {

  ea-renderer-switcher {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .align-self-start;

    @extend .mat-background-card;

    pointer-events: auto;

    // box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);

    line-height: 19px;
    font-size: 16px;

    position: relative;

    > a {

      min-width: 100%;
      line-height: 1.1rem;

      //border-bottom: 2px solid;

      &.active {
        .selected-component {
          @extend .d-flex;
        }
      }

      .content-group {
        background: none;

        &.has-bookmark {

          h4 {
            padding-right: 1.25rem;
          }

        }

      }


      .selected-component {

        @extend .condensed-font;
        display: none !important;

        z-index: 1;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        color: mat.get-contrast-color-from-palette($accent, 500);
        background-color: mat.get-color-from-palette($accent, 500);
        padding-bottom: 15px;

      }

      .free-content-group {
        position: absolute;
        right: 0px;
        bottom: 0px;

        img {
          width: 40px;
          height: 40px;
        }
      }

      ea-practice-renderer, ea-training-renderer {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: hidden;
        position: relative;
      }

      .tag-row-group {
        z-index: 1;
      }

      .tag-row {
        line-height: 10px;
        @extend .px-1;

        &.flex-50{
          flex: 0 0 48% !important;
        }

      }

      .tag-label {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 700;
        // @extend .px-1;
        background-color: mat.get-color-from-palette($accent, default);
        color: mat.get-contrast-color-from-palette($accent, 500);
        // border-radius: 3px;
        box-shadow: 0.5em 0 0 mat.get-color-from-palette($accent, default), -0.5em 0 0 mat.get-color-from-palette($accent, default);
      }

      mat-icon {
        width: 20px;
        height: 20px;
      }

    }

    .bookmark-icon {
      color: var(--user-color-500) !important;
      position: absolute;
      right: 5px;
      z-index: 1;
      width: 24px;
      height: 24px;
      top: 20px;

      &.loading {

        right: 7px;
        top: 26px;

        circle {
          stroke: var(--user-color-500) !important;
        }

      }
    }

  }

}
