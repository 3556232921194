@mixin square-button() {

  box-shadow: none !important;
  border-radius: 0px;
  width: 40px;
  height: 40px;

  .mat-button-wrapper {

    padding: 7px 0;

    .mat-icon {
      height: 32px;
      width: 30px;
    }
  }

}

@mixin ffa-button($direction: both) {

  @extend .uppercase;
  @extend .ambiant-font;
  @extend .font-weight-bold;

  border-radius: 0px !important;

  $triangleWidth: 5px;

  @include chevrons-mixin($triangleWidth, $direction);

  /*
  &:before{
    @include css-triangle($background-color, left, 18px);
    left: 7px;
    transform: scaleX(0.3);
  }

  &:after{
    @include css-triangle($background-color, right, 18px);
    top:0px;
    right: 7px;
    transform: scaleX(0.3);
  }
*/
}

@mixin icon-label-button() {

  line-height: 24px !important;

  .mat-button-wrapper {

    .mat-icon {

      @extend .position-relative;
      @extend .mr-1;

      width: 24px;
      height: 24px;

      @include media-breakpoint-up(sm) {
        width: 18px;
        height: 18px;
      }

      svg {
        @extend .position-absolute;
        top: 0px;
        left: 0px;
      }

    }

  }

}
