@use 'breakpoints' as breakpoints;

a {

  color: inherit !important;

  &:hover, &:active, &:focus {
    text-decoration: none;
    outline: none !important;
  }

}

button {

  color: inherit !important;

  &:hover, &:active, &:focus {
    text-decoration: none;
    outline: none !important;
  }

}

input {

  color: inherit !important;

  &:hover, &:active, &:focus {
    text-decoration: none;
    outline: none !important;
  }

}

label {
  margin: 0;
  margin-bottom: 0;
}

html, body {
  margin: 0;
}

body{
  --font-size-body: 16px;
  @include breakpoints.respond-to('sm'){
    --font-size-body: 14px;
  }
  @include breakpoints.respond-to('lg'){
    --font-size-body: 16px;
  }
  overflow-x: hidden;
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}

figure{
  margin: 0px;
}
