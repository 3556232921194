@use '@angular/material' as mat;
@mixin window($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  background-color: mat.get-color-from-palette($background, background);

  > header{


    background-color: mat.get-color-from-palette($primary, 500);
    color: mat.get-contrast-color-from-palette($primary, 500);

    .close-icon{

      svg{

        path{
          fill: mat.get-contrast-color-from-palette($primary, 500);
        }

      }

    }

  }

  .mat-dialog-content{
    margin: 0px !important;
    color: mat.get-color-from-palette($accent);
    //@include media-breakpoint-only(xs) {
      max-height: none !important;
    //}
  }

  .mat-dialog-actions {
    margin-bottom: 0px;
  }

}
