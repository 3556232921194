@use '@angular/material' as mat;
@import "../content-list/content-list.theme";

@mixin ea-contents-common($theme){

  ea-contents-common{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .p-4;
    @extend .d-block;
    @extend .mat-elevation-z2;
    @extend .position-relative;
    @extend .flex-fill;

    min-width: 850px;
    max-width: 850px;

    user-select: text !important;

    @include ea-content-list($theme);

    ea-content-list{
      background-color: mat.get-color-from-palette($background, background);
    }

    @include media-breakpoint-down(sm) {
      min-width: 100%;
      max-width: 100%;
    }

    @include media-breakpoint-up(sm) {
      min-width: 100%;
      max-width: 100%;
    }

    @include media-breakpoint-up(sm) {
      margin-top: 2em;
      margin-bottom: 2em;
    }

    @include media-breakpoint-up(md) {
      min-width: 510px;
      max-width: 510px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 760px;
      max-width: 760px;
    }

    @include media-breakpoint-up(xl) {
      min-width: 800px;
      max-width: 800px;
    }

    background-color: mat.get-color-from-palette($background, card);

    .loading-group{
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
    }

  }

}
