@mixin ea-faq($theme){

  ea-faq{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @include routed-container();

    @extend .flex-fill;

    ea-page{
      @extend .px-3;
    }

  }

}
