@use '@angular/material' as mat;
@import './encyclopedia-home/encyclopedia-home-theme';
@import './encyclopedia-search/encyclopedia-search-theme';

@mixin ea-encyclopedia($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $typoConfig: mat.define-typography-config();

  ea-encyclopedia {

    @include routed-container(true);

    @include ea-encyclopedia-home($theme);
    @include ea-encyclopedia-search($theme);

    @include media-breakpoint-up(sm) {
      overflow: hidden;
    }

    .mat-tab-links{
      @extend .justify-content-around;
    }

    .main-filters-bar{

      max-width: 1024px;

      .main-filter{
        @include main-filter($theme);
      }

    }

    .top-table{

      z-index: 1;
      padding-top: 27px !important;

    }

    .age-group{

      .age-label{

        &.min{
          text-align: right;
          padding-right: 5px;
        }

        &.max{
          padding-left: 5px;
        }

        min-width:50px;
      }

      ng5-slider{

        width: 100%;

        @include range-slider(26px, map-get($background, status-bar), map-get($accent, 500), mat.get-contrast-color-from-palette($accent, 500));

      }

    }

  }

}
