@mixin ea-content-list($theme){

  ea-content-list{

    pointer-events: none;

    .item-group{
      @include item-renderer();
    }

  }

}
