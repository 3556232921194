@mixin si-ffa-background() {

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.05;
    background-image: url('../../assets/logos/logo-a-internal.svg');
    background-size: 10em;
  }

}
