@mixin range-slider($height, $trackColor, $selectionColor, $handGripsColor) {

  margin: 0px !important;
  height: $height !important;

  $trackSelectionMargin: 2px;

  .ng5-slider-limit{
    display: none !important;
  }

  .ng5-slider-bar-wrapper{

    height:$height !important;
    margin-top: 0px !important;
    padding-top: 0px !important;

    .ng5-slider-bar{
      height: 100% !important;
      background-color: $trackColor;
      border-radius: $height/2 !important;
    }

    .ng5-slider-selection{
      background-color: $selectionColor;
    }

  }

  .ng5-slider-bar-wrapper[ng5sliderselbarelem]{

    transform: translate(-($height/2 - $trackSelectionMargin), 0px);

    .ng5-slider-bar {
      width: calc(100% + (#{$height - 2 * $trackSelectionMargin}));
    }

  }

  .ng5-slider-pointer{

    background-color: $handGripsColor !important;

    &.ng5-slider-pointer-max{
      margin-left: -$trackSelectionMargin;
    }

    &.ng5-slider-pointer-min{
      margin-left: $trackSelectionMargin;
    }

    top: $trackSelectionMargin !important;

    &:after{
      background: none !important;
    }

    width:$height - 2 * $trackSelectionMargin !important;
    height:$height - 2 * $trackSelectionMargin !important;
    border-radius: $height/2 - $trackSelectionMargin !important;
  }

  .ng5-slider-bubble{
    display: none !important;
  }

}
