@use '@angular/material' as mat;
@mixin ea-renderer-header($theme){

  ea-renderer-header{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .d-flex;
    @extend .align-items-center;
    @extend .px-1;
    @extend .default-font;
    @extend .font-size-mini;
    @extend .font-weight-bold;

    background-color: mat.get-color-from-palette($accent, 500);
    color: mat.get-contrast-color-from-palette($accent, 500);

    min-height: 20px;
    max-height: 20px;

    &.formation_diplome{
      background-color: mat.get-color-from-palette($accent, 700);
    }

  }

}
