@use '@angular/material' as mat;
@mixin ea-app-snackbar($theme){

  ea-app-snackbar{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .d-flex;
    @extend .align-items-center;
    @extend .flex-column;
    //@extend .pr-3;

    background-color: mat.get-color-from-palette($background, card);

    img{

      max-width: 48px;
      max-height: 48px;

    }

  }

}
