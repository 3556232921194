@use '@angular/material' as mat;
@import '../components/app-header/app-header-theme';
@import '../components/app-footer/app-footer-theme';
@import '../components/app-menu/app-menu-theme';
@import '../../search/containers/search-theme';
@import '../../components/loading/loading-theme';
@import '../../components/user-login/containers/user-login-theme';
@import '../../components/promoted-content-bloc/promoted-content-bloc-theme';
@import '../../components/sponsors/sponsors-theme';
@import '../../components/transversal-search-field/transversal-search-field-theme';
@import '../../encyclopedia/container/encyclopedia-theme';
@import '../../themes/containers/themes-theme';
@import '../../contents/containers/contents-theme';
@import '../../home/home-theme';
@import '../../promoted-contents/container/promoted-contents-bloc-theme';
@import '../../tutorials/components/tutorial-renderer/tutorial-renderer.theme';

@mixin ea-root($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $typoConfig: mat.define-typography-config();

  ea-root {

    @include make-row();

    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    .loading-logo{
      width: 60vw;
      max-width: 400px;
    }

    @extend .container-fluid;

    @extend .flex-column;
    @extend .flex-nowrap;

    @include media-breakpoint-up(xs) {
      @extend .flex-fill;
    }

    @extend .d-flex;

    @include ea-app-header($theme);
    @include ea-app-footer($theme);
    @include ea-app-menu($theme);

    @include ea-promoted-content-bloc($theme);

    @include ea-encyclopedia($theme);
    @include ea-themes($theme);
    @include ea-home($theme);
    @include ea-sponsors($theme);
    @include ea-practice-renderer($theme);
    @include ea-transversal-search-field($theme);
    @include ea-line-practice-renderer($theme);

    @include ea-promoted-contents-bloc($theme);

    @include ea-tutorial-renderer($theme);


    .mat-drawer-container {
      color: inherit !important;
    }

    .bookmarks-side-nav, .mat-drawer-inner-container {
      background: transparent;
    }

    > .root-group {

      .mat-drawer-backdrop {
        background: transparent;
      }

      .mat-drawer-inner-container {
        @extend .d-flex;
        @extend .flex-column;
      }

      > mat-sidenav {

        > .mat-drawer-inner-container {
          @extend .justify-content-between;
        }

      }

      .back-search{

        .count{
          margin-top: -5px;
        }

      }

      > .content-group {

        transition: padding-top 0.5s;

        height: auto;
        min-width: 100%;
        min-height: 100%;
        max-height: 100%;

        // padding-top: $header-height;

        &.home-active {
          // padding-top: $header-height;
        }

        &.search-active {
          // padding-top: calc(#{$header-search-height} + var(--search-infos-bar-height));
        }

        &.content-active {
          // padding-top: calc(#{$header-content-height} + var(--search-infos-bar-height));
        }

        &.session {
          // padding-top: $header-content-height;
        }

        header {
          position: sticky;
          top: 0;
          width: 100%;
          z-index: 3;

          .search-field-group {

            filter: drop-shadow(0px 0px 1px mat.get-color-from-palette($primary, 500));

            .search-field {
              width: 100%;
              max-width: 990px;
            }

          }

        }

      }

    }


    ea-loading {
      position: fixed !important;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      z-index: 10000;
    }

    footer {
      z-index: 1;
    }

    .filters-button{

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      transition: opacity 0.4s;

      &.active{
        opacity: 0.5;
      }

      .mat-button-wrapper{

        display: flex;
        align-items: center;

        mat-icon{
          display: flex;
        }

      }
    }

  }

}
