@use '@angular/material' as mat;
@mixin ea-question-answers($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-question-answers{

    @extend .flex-column;
    @extend .flex-fill;

    overflow: hidden;
    display: flex;
    z-index: 1;

    a{

      //color: mat.get-color-from-palette($accent, 500) !important;

    }

    .edit-button{
      box-shadow: none !important;
      position: absolute;
      bottom: 5px;
      right: 5px;
      border: white 1px solid;
    }

    .icon-chrono{
      height: 26px;
    }

  }

}
