@mixin ea-content-header($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-content-header {

    .title {
      line-height: 1.1em;
    }

    .button-group {

      list-style-type: none;

      button, a {
        @include square-button();
        margin-right: 1px;
        color: white !important;
      }

    }

    .bookmark-icon-group {

      position: absolute;
      top: 0;
      right: 20px;

      mat-icon {

        width: 36px;
        height: 36px;
        color: var(--user-color-500) !important;

        &.add-to-bookmarks{
          color: var(--encyclopedia-color-500) !important;
        }

      }

    }

  }

}
