@use '@angular/material' as mat;
@mixin ea-training-renderer($theme) {

  ea-training-renderer {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .condensed-font;
    @extend .p-2;
    @extend .flex-grow-1;
    @extend .overflow-hidden;

    color: mat.get-color-from-palette($primary, 500);
    background-color: mat.get-color-from-palette($background, card);
    display: block;
    //min-height: 103px;
    //max-height: 103px;

    padding-top: 5px !important;

  }

  img{
    object-fit: cover;
  }

  ea-training-renderer:after {
    content: "";
    display: block;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 71%, white 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}
