@use '@angular/material' as mat;
//https://github.com/angular/material2/blob/master/guides/typography.md

html {

  height: 100%;

}

body {

  min-height: 100%;

  /*@include media-breakpoint-between(sm, xl) {
    height: 100%;
    min-height: initial;
  }*/

  $primary: map-get($app-theme, primary);

  @extend .d-flex;

  font-family: mat.font-family($app-typography);
  font-size: mat.font-size($app-typography, body-1);
  user-select: none;

  color: mat.get-color-from-palette($primary, 500);

  /*@include media-breakpoint-between(sm, xl) {
    overflow: hidden;
  }*/

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  div {
    display: block;
  }

  li {
    display: list-item;
  }

  span, strong, small {
    display: inline;
  }

  script, style {
    display: none;
  }

  > .root-group {
    display: flex;
    width: 100%;
    height: 100%
  }

  .rich-text-group {

    a {
      text-decoration: underline;
      color: var(--user-color-500) !important;
    }

  }

}
