@use '@angular/material' as mat;
@mixin ea-sponsors($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-sponsors{

    background-color: mat.get-color-from-palette($background, card);
    border-radius: 5px 5px 0px 0px;

    li{

      max-width: 64px;

      @include media-breakpoint-between(md, xl) {
        max-width: 90px;
      }

      img{
        width: 100%;
      }

    }

    .dima-logo{

      @include media-breakpoint-between(md, xl) {
        max-width: 140px;
      }

    }

    .creditmutuel-logo{

      @include media-breakpoint-between(md, xl) {
        max-width: 140px;
      }

    }

    .min-logo{

      @include media-breakpoint-between(md, xl) {
        max-width: 160px;
      }

    }

    .maif-logo{

      @include media-breakpoint-between(md, xl) {
        max-width: 80px;
      }

    }

    /*
    .dima-logo{
      max-width: 128px;
    }

    .maif-logo{
      max-width: 128px;
    }

    .min-logo{
      max-width: 128px;
    }

    .asics-logo{
      max-width: 128px;
    }
    */

  }

}
