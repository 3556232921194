@use '@angular/material' as mat;
@mixin ea-loading($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-loading {

    @extend .position-absolute;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    min-width: 100%;
    min-height: 100%;
    top:0px;
    left:0px;
    pointer-events: none;

    //background: mat.get-color-from-palette($background, background, 0.2);

    .loading-icon-group {
      @include loading(40px);
      background: mat.get-color-from-palette($background, background);
      border-radius: 40px;
      border: 1px solid mat.get-color-from-palette($accent, 500, 0.3);
    }

  }

}
