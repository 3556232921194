@mixin ea-seance($theme){

  ea-seance{

    mat-accordion{

      @include media-breakpoint-only(xs) {
        margin-left: -24px;
        margin-right: -24px;
      }

    }

  }

}
