@use '@angular/material' as mat;
@mixin main-filter($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  &.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
    transform: translateY(-0.5em) scale(0.5) perspective(100px) translateZ(0.001px);
  }

  &.mat-form-field-appearance-legacy .mat-form-field-label{
    color: mat.get-contrast-color-from-palette($accent, 500);
  }

  &.mat-form-field-type-mat-select{

    height: 50px;
    border-right:  mat.get-color-from-palette($foreground, disabled-button) 1px solid;
    padding: 0 1rem;

    .mat-form-field-wrapper{

      padding-bottom: 0px;
      min-height: 100%;

      .mat-form-field-flex{

        min-height: 100%;
        height: 50px;

        .mat-form-field-infix{

          padding: 0;
          border: 0;
          height: 50px;

          .mat-select{

            min-height: 100%;

            .mat-select-trigger{

              height: 50px;

              .mat-select-value{
                @extend .font-weight-bold;
                @extend .font-size-subheading-2;
                height: 50px;
                vertical-align: middle;
                color: mat.get-contrast-color-from-palette($accent, 500);
              }

              .mat-select-arrow{
                color: mat.get-contrast-color-from-palette($accent, 500);
              }

            }
          }

          .mat-form-field-label-wrapper{

            @extend .uppercase;
            @extend .font-weight-bold;
            @extend .ambiant-font;
            @extend .font-size-title;

            top: -11px;
            padding-top: 0px;

          }

        }

      }

      .mat-form-field-underline{
        display: none;
      }

    }

  }

}
