@use '@angular/material' as mat;
@mixin ea-training-item-link($theme){

  ea-training-item-link{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    @extend .d-flex;

    background-color: mat.get-color-from-palette($accent, 200);
    color: mat.get-color-from-palette($accent, 700);

    p{
      line-height: 1.2em;
    }

  }

}
