@mixin chevrons-mixin($triangleWidth, $direction: both) {

  @if $direction == both {
    clip-path: polygon(calc(100% - #{$triangleWidth}) 0, 100% 50%, calc(100% - #{$triangleWidth}) 100%, #{$triangleWidth} 100%, 0 50%, #{$triangleWidth} 0);
  } @else if $direction == left {
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, #{$triangleWidth} 100%, 0 50%, #{$triangleWidth} 0);
  } @else if $direction == right {
    clip-path: polygon(calc(100% - #{$triangleWidth}) 0, 100% 50%, calc(100% - #{$triangleWidth}) 100%, 100% 100%, 0 100%, 0 0);
  }

}
