@use '@angular/material' as mat;
@mixin ea-diaporama($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-diaporama {

    background-color: mat.get-color-from-palette($background, card);
    width: 100%;

    &.fullscreen {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .swiper-slide {

      img {
        width: 100%;
      }

    }

    .pagination-group {

      .bullet {
        width: 16px;
        height: 16px;
      }

      button[disabled=true]{
        opacity: 0.5;
      }

    }

  }

}
