@mixin ea-training-tools($theme){

  ea-training-tools{

    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;

    .body-group{

      a{
        text-decoration: underline;
      }

    }

    @include ea-content-list($theme);

  }

}
