@use '@angular/material' as mat;
@import '../components/search-card/search-card-theme';

@mixin ea-search($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $typoConfig: mat.define-typography-config();

  ea-search {

    @include ea-search-card-list($theme);

    @extend .d-flex;
    @extend .flex-fill;
    @extend .flex-column;
    @extend .overflow-hidden;

    ul{
      list-style-type: none;
    }

    .header{
      min-height: 5rem;
      background-color: mat.get-color-from-palette($accent, 600);
      color: mat.get-contrast-color-from-palette($accent, 600);
    }

    .chip-button{
      width: 1.5em;
      margin-top: -4px;
      margin-right: -7px;

      .mat-button-wrapper{
        line-height: 1em;
        mat-icon {
          font-size: inherit;
          width: 1em;
          height: 1.125em;
          line-height: 1.125em;
        }

      }

    }

    mat-sidenav{

      --filters-pb : 3rem;
      border: none;

      .filters-group{

        ea-search-filters{

          width: 85vw;

          @include media-breakpoint-up(sm){
            width: 376px;
            padding-right: 12px;
            margin-right: -25px;
          }
          // padding-left: 10px;
          padding-right: 10px;
          overflow-y: scroll;
          padding-bottom: var(--filters-pb);
        }

      }

      .filters-group:after {
        content: "";
        display: block;
        background: linear-gradient(180deg, mat.get-color-from-palette($background, card, 0) 0%, mat.get-color-from-palette($background, card) 100%);
        height: var(--filters-pb);
        width: 100%;
        position: absolute;
        pointer-events: none;
        left: 0;
        bottom: 0;
      }

    }

    mat-sidenav-content{

      .open-filters{

        @extend .d-flex;

        flex: 0 0 50px;
        max-width: 50px;
        cursor: pointer;

        .filters-icon{
          margin-top: 5px;
          margin-left: -2px;
        }

        .title{
          @extend .text-uppercase;
          @extend .font-weight-bold;
          @extend .font-size-title;
          transform: rotate(-90deg);
        }

      }

      .content-group{
        max-width: 100%;
      }

    }

    .mat-drawer-backdrop.mat-drawer-shown {
      background-color: transparent;
    }

    .extended-type-filter{
      @include extended-types-filters();
    }

    .cancel-filters{
      background-color: mat.get-color-from-palette($background, card) !important;
      color: mat.get-color-from-palette($primary, 500) !important;
      border: mat.get-color-from-palette($primary, 500) 2px solid !important;
      font-weight:bold;
    }

  }

}
