@mixin ea-starting-screen($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-starting-screen{

    @extend .d-flex;
    @extend .flex-fill;
    @extend .flex-column;

    z-index: 1;

    a{

      text-decoration: underline;
      @extend .subheading-2;

    }

    p{
      margin: 0px;
    }

    .icon-chrono{
      width: 80px;
      height: 80px;
    }

  }

}
