@use '@angular/material' as mat;
@mixin ea-search-filters($theme) {

  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-search-filters {

    @extend .d-flex;
    @extend .flex-column;

    $smallButtonSize: 14px;

    $mediumButtonSize: 18px;

    color: mat.get-color-from-palette($foreground, text);

    @extend .pt-3;

    .mat-button-toggle-checked{
      background-color: mat.get-color-from-palette($primary, 500);
      color: mat.get-contrast-color-from-palette($primary, 500) !important;
    }

    ul, li {
      list-style-type: none;
    }

    ul {
      padding-left: 0.25rem;
    }

    li {
      padding-left: 0.5rem;
    }

    /*.mat-standard-chip {

      @extend .font-size-caption;

      border-radius: 12px;
      height: 24px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 11px;
      padding-right: 6px;
      min-height: 24px;
      background-color: mat.get-color-from-palette($background, filter-chip-active) !important;

    }*/

    .small-icon {
      height: $smallButtonSize;
      width: $smallButtonSize;
    }

    .medium-icon {
      height: $mediumButtonSize;
      width: $mediumButtonSize;
    }

    .small-button {
      width: $smallButtonSize;
      height: $smallButtonSize;
      line-height: $smallButtonSize;
    }

    .mat-badge-content {
      font-size: 10px;
      right: -24px !important;
      top: -6px !important;
      background: none !important;
      color: inherit !important;
    }

    .mat-tree-node {
      min-height: 36px;
    }

    .parent-filter-label {

      &.active {

        @extend .font-weight-bold;

      }

    }

    .mat-checkbox-frame {
      border-width: 1px;
      border-style: solid;
      border-radius: 1px;
      border-color: rgba(255, 255, 255, 0.25);
    }

    .mat-checkbox-checkmark {

      fill: white !important;

      .mat-checkbox-checkmark-path {
        stroke: white !important;
        stroke-width: 2px;
      }

    }

    .advanced-group {
      .mat-expansion-panel-body {
        // @extend .p-0;
      }
    }

    .main-filters-group {

      li {
        @extend .p-0;
      }

    }

    mat-button-toggle {

      overflow: visible;
      border: none !important;

      button {

        font-weight: normal !important;
        font-family: mat.font-family($app-typography) !important;
        text-transform: capitalize !important;

        .mat-button-toggle-label-content {
          width: 100%;
          text-align: left;
        }

      }

      width: 100%;

    }

    .mat-radio-inner-circle {
      background-color: mat.get-color-from-palette($foreground, text);
    }

    .expand-filters {

      .mat-button-wrapper {

        @extend .d-flex;

        mat-icon {

          @extend .position-relative;

          svg {

            @extend .position-absolute;
            top: 0;
            left: 0;

          }

        }

      }
    }

    .filter-link {

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

    }

    .extended-type-filter {

      @include extended-types-filters();

    }


  }

}
