@use '@angular/material' as mat;
@mixin ea-app-header($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-app-header {

    @extend .d-flex;
    @extend .flex-column;
    @extend .position-relative;

    background: linear-gradient(90deg, #00003B 0%, #0D2366 50%, #00003B 100%);
    color: mat.get-contrast-color-from-palette($primary, 500);

    min-height: $header-content-height;

    transition: min-height 0.5s, padding-bottom 0.5s;

    &:not(.home-active){

      //padding-bottom: 0px;

    }

    .bookmark-icon{
      margin-left: 11px !important;;
      margin-top: -19px !important;;
      height: 30px !important;
      width: 30px !important;;
    }

    .user-login-group{
      height: var(--header-content-height);
    }

    &.home-active{

      min-height: $header-height;
      padding-bottom: 16px;

    }

    &.search-active, &.home-scrolling{

      min-height: $header-search-height;
      padding-bottom: 16px;

    }

    &.content-active{

      min-height: $header-content-height;
      padding-bottom: 0;

      .back-layer{

        padding-bottom: 0;

      }

    }

    .back-layer{

      transition: padding-bottom 0.5s;
      padding-bottom: 16px;

    }

    .logo-ffa{
      padding-top: 1px;
    }

    .main-menu{
      z-index:1;
    }

    .header-button{

      @extend .px-2;
      @extend .px-sm-2;

      min-width:24px;
      font-size:14px;

      .mat-button-wrapper{
        @extend .default-font;
        font-weight: normal;
      }

    }

    .loading-icon-group{
      @include loading(20px);
    }

    .user-login-group{

      top:0;
      right:0;

      /*@include media-breakpoint-up(sm) {
        right: 0;
      }*/

      .user-button{
        padding: 0 !important;
      }

    }

    .loading-icon-container{
      top: 8px;
      right: 5px;
    }

    $subscribeBtnHeight: var(--header-content-height);

    .subscribe-btn{

      min-height: $subscribeBtnHeight;
      display: flex;
      align-items: center;
      line-height: $subscribeBtnHeight !important;
      padding-left: calc(#{$subscribeBtnHeight}/2) !important;
      padding-right: $subscribeBtnHeight !important;
      background: linear-gradient(270deg, #008CCC 0%, #02BCFF 100%);

      @include ffa-button(left);

      .mat-button-wrapper{
        display: flex;
      }

      .icon{
        top: 0;
        right: 0;
        width: $subscribeBtnHeight;
        height: $subscribeBtnHeight;
      }

    }

  }

}
