@use '@angular/material' as mat;
@mixin search-filters($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  background-color: mat.get-color-from-palette($background, card) !important;
  color: mat.get-color-from-palette($foreground, text);

}
