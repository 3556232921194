@use '@angular/material' as mat;
@mixin vertical-menu($theme) {

  .ea-vertical-menu{

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, background);

    .active{
      color: mat.get-contrast-color-from-palette($primary, 500);
      background-color: mat.get-color-from-palette($primary, 500);
    }

  }

}
