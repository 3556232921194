@use '@angular/material' as mat;
@import '../../components/answers/answers-theme';
@import '../../components/quiz-gauge/quiz-gauge-theme';
@import '../../components/ending-screen/ending-screen-theme';
@import '../../components/starting-screen/starting-screen-theme';
@import '../question-answers/question-answers-theme';
@import '../../components/results/results.theme';

@mixin ea-quiz($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  @include ea-answers($theme);
  @include ea-question-answers($theme);
  @include ea-quiz-gauge($theme);
  @include ea-ending-screen($theme);
  @include ea-starting-screen($theme);
  @include ea-results($theme);


  .background-stage-group, &.background-stage-group {
    &.quiz-theme {
      @include media-breakpoint-up(sm) {
        @include aspect-ratio(4, 3);
      }
    }

  }

  .background-stage-group, &.background-stage-group {

    // background-color: mat.get-color-from-palette($background, background);

    &.fullscreen {

      position: fixed;
      /*width:100%;
      height: 100%;
      top:0px;
      left:0px;
      z-index: 10;

      @extend .justify-content-center;
      @extend .align-items-center;

      .stage-group {
        min-width: 800px;
        max-width: 800px;
        transform: scale(1.35);
        box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.81);
      }*/

    }

    .stage-group {

    }

    &.quiz-theme {

      background-color: mat.get-color-from-palette($background, background);
      color: mat.get-contrast-color-from-palette($accent, 500);


      .stage-group {
        height: 100%;
      }

      .stage-group:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;
        top: 0;
        left: 0;
        opacity: 0.3;
      }

      .stage-group.start:before, .stage-group.try-again:before {
        background-image: url('../../../../assets/quiz/quiz_back_depart.svg');
      }

      .stage-group.questions:before {
        background-image: url('../../../../assets/quiz/quiz_back_ligne.svg');
      }

      .stage-group.result:before {
        background-image: url('../../../../assets/quiz/quiz_back_arrivee.svg');
      }

    }

  }

}
