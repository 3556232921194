@mixin ea-encyclopedia-bloc($theme){

  ea-encyclopedia-bloc{

    @include home-main-bloc($theme);

    h2{
      line-height: 35px;
    }

    > a{

      >p{
        max-width: 780px;
      }

    };

  }

}
