@use '@angular/material' as mat;
@mixin home-main-bloc($theme) {

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);

  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;

  //@extend .mt-lg-n4;

  .top-group{
    @extend .pt-2;
    background-color: mat.get-color-from-palette($accent) ;
  }

  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: mat.get-color-from-palette($accent, '500-contrast', 0.38) !important;
  }

  .mat-tab-header-pagination-chevron {
    border-color: mat.get-color-from-palette($accent, '500-contrast', 1) !important;
  }

  .mat-tab-label {
    padding: 0 20px;
    min-width: 120px;
  }

  //border-top: mat.get-color-from-palette($accent) solid 5px;
  //background-color: mat.get-color-from-palette($background, background);
  color: mat.get-contrast-color-from-palette($primary, 500) !important;

}
