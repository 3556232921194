@use '@angular/material' as mat;
@mixin ea-training-bloc($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-training-bloc{

    @extend .d-flex;
    @extend .flex-column;

    .main-bloc {
      background-color: mat.get-color-from-palette($accent, 700);
      color: mat.get-contrast-color-from-palette($accent, 700);
    }

    p {
      margin-bottom: 0px;
    }

    .secondary-bloc {
      background-color: mat.get-color-from-palette($accent, 500);
      color: mat.get-contrast-color-from-palette($accent, 500);
    }

    mat-divider {
      border-top-color: mat.get-color-from-palette($accent, 700) !important;
    }

    .link-button {
      @include ffa-button(left);
      background-color: mat.get-color-from-palette($accent, 700);
      color: mat.get-contrast-color-from-palette($accent, 700);
    }

  }


}
