@use '@angular/material' as mat;
@import "trainee-module/trainee-module.theme";

@mixin ea-trainee-area($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-trainee-area {

    @include ea-trainee-module($theme);
    @include routed-container(true);

    background-repeat: no-repeat !important;
    background-size: cover !important;

    background-color: black !important;

    overflow-y: auto;

    @extend .align-items-center;

    >.content-group{

      max-width: 690px;

      .dark-text{
        color: mat.get-contrast-color-from-palette($accent, 500);
      }

    }

    button{
      background-color: mat.get-color-from-palette($accent, 500) !important;
      color: mat.get-contrast-color-from-palette($accent, 500) !important;;
    }

  }

}
