@mixin ea-ending-screen($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-ending-screen{

    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    @extend .justify-content-around;


    button{

      &.disabled{
        opacity: 0.5;
        pointer-events: none;
      }

    }

  }

}
