@use '@angular/material' as mat;
@mixin ea-pwa-install($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-pwa-install{

    @extend .p-4;
    @extend .d-flex;
    @extend .flex-column;

    background-color: mat.get-color-from-palette($primary, 500);
    color: mat.get-contrast-color-from-palette($primary, 500);

    .app-icon{
      min-width: 50px;
      width: 50px;
      max-width: 50px;
      min-height: 50px;
      height: 50px;
      max-height: 50px;
    }

    p{
      margin-bottom: 0px;
    }

    .content-group{
      color: mat.get-color-from-palette($accent, 500) !important;
    }

    button{
      @include ffa-button();
      background-color:mat.get-color-from-palette($accent, 500) !important;
      color:mat.get-contrast-color-from-palette($accent, 500) !important;
    }

  }

}
