@mixin ea-list-slider($theme){

  ea-list-slider{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    flex-shrink: 0;
    display: flex;
    flex: none;
    align-items: stretch;

    swiper{
      display: flex !important;
      flex-direction: column !important;
      align-self: stretch;
      max-width: 100%;
      position: relative !important;
    }

    .swiper.s-wrapper{
      height: auto !important;
      display: flex;
      flex-direction: column;
    }

    .swiper-slide {
      width: 100% !important;
    }

    .swiper-pagination-handle {
      display: flex !important;
      padding: 4px !important;
      margin: 2px !important;
    }

    .swiper-pagination-bullet {
      @extend .mat-background-accent-color;
      border: none !important;
    }

    .swiper-pagination-bullet-active {
      @extend .mat-background-primary-color;
    }

    .swiper-pagination{

      position: relative !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      bottom: auto;
      margin-top: 10px;
      margin-bottom: 20px;

      .swiper-pagination-bullet{
        width: 10px !important;
        height: 10px !important;
      }

    }




  }

}
