@use '@angular/material' as mat;

@mixin mat-ffa-typography($config, $selector: '.mat-typography') {

  @include mat.typography-hierarchy($config);

  .mat-h1, .mat-headline, #{$selector} h1 {
    margin: 0 0 0px;
  }

  .mat-h2, .mat-title, #{$selector} h2 {
    margin: 0 0 0px;
    line-height: 1.15em;
  }

  .mat-h3, .mat-subheading-2, #{$selector} h3 {
    margin: -5px 0 0px;
    text-transform: uppercase;
  }

  .mat-h4, .mat-subheading-1, #{$selector} h4 {
    margin: 0 0 0px;
  }

  // Note: the spec doesn't have anything that would correspond to h5 and h6, but we add these for
  // consistency. The font sizes come from the Chrome user agent styles which have h5 at 0.83em
  // and h6 at 0.67em.
  .mat-h5, #{$selector} h5 {
    margin: 0 0 12px;
  }

  .mat-h6, #{$selector} h6 {
    margin: 0 0 12px;
  }

  .mat-body-strong, .mat-body-2 {
    @include mat.typography-level($config, body-2);
  }

  .mat-body, .mat-body-1, #{$selector} {

    @include mat.typography-level($config, body-1);

    p {
      margin: 0 0 12px;
    }

    p:only-of-type {
      margin-bottom: 0px;
    }

  }

  .subheading-1 {
    @include mat.typography-level($config, subheading-1);
  }

  .subheading-2{
    @include mat.typography-level($config, subheading-2);
  }

  .mat-small, .mat-caption {
    @include mat.typography-level($config, caption);
  }

  // Note: The spec doesn't mention letter spacing. The value comes from
  // eyeballing it until it looked exactly like the spec examples.
  .mat-display-4, #{$selector} .mat-display-4 {
    margin: 0 0 0px;
  }

  .mat-display-3, #{$selector} .mat-display-3 {
    margin: 0 0 0px;
  }

  .mat-display-2, #{$selector} .mat-display-2 {
    margin: 0 0 0px;
  }

  .mat-display-1, #{$selector} .mat-display-1 {
    margin: 0 0 0px;

    @include media-breakpoint-only(xs){
      font-size: 28px;
      line-height: 34px;
    }

  }

  .body-1 {
    @include mat.typography-level($config, body-1);
  }

  .body-2 {
    @include mat.typography-level($config, body-2);
  }

  .mat-title {
    @include mat.typography-level($config, title);
  }

  .caption {
    @include mat.typography-level($config, caption);
  }

  .main-font{
    font-family: mat.font-family($config);
  }

}
