@mixin tutorials($theme) {

  ea-tutorials{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    mat-drawer{

      min-width: 335px;
      max-width: 335px;

      background-color: map-get($background, background) !important;;
      border: none !important;

    }

  }

}
