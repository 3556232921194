@use '@angular/material' as mat;

@mixin ea-situation($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-situation {

    .media-group{
      background-color: mat.get-color-from-palette($primary, 900);
    }

    .video-tab-group{

      background-color: mat.get-color-from-palette($background, card);
      overflow: hidden;

      .mat-primary {
        color: mat.get-color-from-palette($primary);
      }

      mat-tab-header{

        border-color: mat.get-color-from-palette($accent, 500);

        .mat-tab-label{
          opacity: 1!important;
        }

        .mat-tab-label-active{
          color: mat.get-color-from-palette($accent, 500);
          font-weight: 700;
        }

        .mat-ink-bar{
          height: 3px;
        }

      }

      .mat-tab-body{
        aspect-ratio: 4/3;
      }

      ea-video-player{
        max-width: 100%;
      }

    }

  }

}
