@mixin ea-user-infos-form($theme){

  ea-user-infos-form{

    .loading-icon-group{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(243,243,243,0.5);
    }

    .date-button{

      .mat-form-field-label-wrapper{
        // display: none;
      }

      .mat-form-field-infix{
        padding-bottom: 0px !important;
      }

      input{
        display: none;
      }

      .mat-form-field-underline, .mat-form-field-subscript-wrapper{
        display: none;
      }

      button{
        text-transform: initial !important;

        .mat-button-wrapper{
          font-weight: normal;
          text-transform: uppercase;
        }

        &.mat-accent{
          color: white !important;
        }

      }

    }

  }

}
