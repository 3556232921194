@use '@angular/material' as mat;
@mixin ea-bookmarks($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-bookmarks {

    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-fill;
    position: relative;

    overflow: hidden;

    width: 100vw;
    max-width: 928px;

    --height-bookmarks-panel: 50px;

    background-color: mat.get-color-from-palette($background, card);

    @include ea-content-list($theme);

    mat-tab-group{
      margin-top: var(--height-bookmarks-panel);
      @include media-breakpoint-up(sm){
        margin-top:0;
      }
    }

    .mat-expansion-panel-body {
      padding: 0 5px 16px;
    }

    .title-close-group {
      @extend .layer-group;
      height: var(--height-bookmarks-panel);

      button {

        z-index: 1;
        width: var(--height-bookmarks-panel);
        height: var(--height-bookmarks-panel);
        display: flex;
        flex-direction: column;

        &.mat-icon-button {

          .mat-button-wrapper {

            display: flex;
            flex: 1 1 auto;
            align-self: stretch;
            justify-content: center;
            align-items: center;

            mat-icon {
              width: 32px;
              height: 32px;
              line-height: 32px;
            }
          }

        }

      }
    }

    h1 {
      font-size: 22px;
    }

    .title-icon {

    }

    mat-expansion-panel{

      background: mat.get-color-from-palette($background, background) !important;

      &.fiche_peda, &.cycle, &.enchainement_situations, &.situation_jeunes_juges, &.seance, &.ffa_theme{
        @include bookmark-panel(var(--practive-color-500));
      }

      &.theoretical_content{
        @include bookmark-panel(var(--practive-color-500));
      }

      &.quiz{
        @include bookmark-panel(var(--quiz-color-500));
      }

      &.ffa_video_athle_tv{
        @include bookmark-panel(var(--video-color-500));
      }

      &.formation_module{
        @include bookmark-panel(var(--module-color));
      }

      &.formation_diplome{

      }@include bookmark-panel(var(--diplome-color));

    }

    mat-tab-group {

      &.encyclopedia {

        .mat-ink-bar {
          background-color: var(--encyclopedia-color-500) !important;
        }

      }

      &.training {

        .mat-ink-bar {
          background-color: var(--training-color-500) !important;;
        }

      }

      mat-tab-header {

        border: none !important;

        .mat-tab-label {

          opacity: 1 !important;
          background-color: transparent !important;

          .tab-label {

            @extend .condensed-font;
            @extend .font-weight-bold;
            font-size: 22px;

            &.encyclopedia-label {
              color: var(--encyclopedia-color-500);
            }

            &.training-label {
              color: var(--training-color-500);
            }

          }

        }

      }

      .mat-tab-body-wrapper {
        @extend .flex-fill;
      }

    }

    .anonymous-group{
      margin-top: var(--height-bookmarks-panel);

      ea-subscription-bloc{
        display: flex;
        align-self: stretch;
        justify-content: center;
        @extend .py-5;
      }

    }

    .intro{
      max-width: 460px;
    }


  }

}

@mixin bookmark-panel($color){

  border-radius: 0;
  border-top: 5px $color solid;

  mat-panel-description{
    color: $color !important;;
  }

  .mat-expansion-indicator::after{
    color: $color !important;
  }

  .mat-content{

    position: relative;

    mat-panel-description{
      position: absolute;
      width: 100%;
      justify-content: center;
    }

  }

}
