@use '@angular/material' as mat;

$app-typography: mat.define-typography-config(
  $font-family: '"Barlow", "Arial", "Helvetica Neue", sans-serif',
  $display-4: mat.define-typography-level(112px, 112px, 300),
  $display-3: mat.define-typography-level(56px, 56px, 400),
  $display-2: mat.define-typography-level(40px, 40px, 400),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 30px, 400),
  $title: mat.define-typography-level(20px, 23px, 500),
  $subheading-2: mat.define-typography-level(18px, 20px, 700),
  $subheading-1: mat.define-typography-level(18px, 20px, 400),
  $body-2: mat.define-typography-level(16px, 20px, 500),
  $body-1: mat.define-typography-level(var(--font-size-body), 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(16px, 16px, 500),
    // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(inherit, 1.125, 400)
);
@include mat.core($app-typography);


@import '~bootstrap-scss/functions';
@import '~bootstrap-scss/variables';
@import "~bootstrap-scss/mixins";

@import '~bootstrap-scss/reboot';
@import '~bootstrap-scss/bootstrap-grid';
@import '~bootstrap-scss/utilities/text';
@import '~bootstrap-scss/utilities/flex';
@import '~bootstrap-scss/utilities/position';
@import '~bootstrap-scss/utilities/sizing';
@import '~bootstrap-scss/utilities/spacing';
@import '~bootstrap-scss/tables';

@import "styles-variables";
@import "mixins";

@import "styles-reset";
@import 'fonts';
@import "themes/themes";
@import "document";
@import 'separators';
@import 'cookie-bot';

.mat-mini-fab {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.layer-group {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mat-checkbox-layout {
  white-space: pre-line !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #0d2366;
  border: 2px solid #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0d2366;
}

::-webkit-scrollbar-thumb:active {
  background: #0d2366;
}

::-webkit-scrollbar-track {
  background: #ffffff !important;
  border: 0 none #ffffff;
  border-radius: 4px;
}

::-webkit-scrollbar-track:hover {
  background: #ffffff;
}

::-webkit-scrollbar-track:active {
  background: #ffffff;
}

::-webkit-scrollbar-corner {
  background: #ffffff;
}
