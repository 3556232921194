@use '@angular/material' as mat;
@use 'breakpoints' as breakpoints;

@mixin ea-tutorial-renderer($theme){

  ea-tutorial-renderer{

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    display: block;
    height: 90px;

    @include breakpoints.respond-to('sm'){
      height: initial;
    }

    overflow: hidden;

    a{

      display: flex;
      gap: 0.75em;
      height: 100%;
      overflow: hidden;
      background-color: white;
      position: relative;

      &.active{
        opacity: 0.4;
        pointer-events: none;
      }

      .image-group{
        flex: 0 0 160px;
        @include breakpoints.respond-to('sm'){
          flex: none;
        }
      }

      .title-group{

        flex: 1 1 auto;

        @include breakpoints.respond-to('sm'){
          flex: none;
          position: absolute;
          z-index: 1;
          bottom:0;
          left:0;
          right:0;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
          color: mat.get-color-from-palette($primary, default-contrast);
          padding: 1em 1em 0 1em;
        }

        h4{
          line-height: 1.2em;
        }

      }
    }


  }

}
