@mixin ea-search-card-list($theme){

  ea-search-card{

    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-fill;

    position: relative;


    virtual-scroller{

      top: 0;
      bottom:0;

    }

    &.mini-search{

      virtual-scroller {
        top: 0 !important;
      }

    }

    .item-group{

      @include item-renderer();

    }

    .loading-group{
      width: 100%;
      height: 130px;
    }

  }

}
