@use '@angular/material' as mat;
@mixin ea-user-subscription($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-user-subscription {

    mat-horizontal-stepper{

      background-color: mat.get-color-from-palette($background, background) !important;
      pointer-events: none;

      .mat-horizontal-content-container{
        display: none;
      }
      .mat-horizontal-stepper-header{
        height: auto;
      }

    }

  }

}
