@mixin ea-subscription-bloc($theme){

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-subscription-bloc{

    .subscribe-bloc{

      @include media-breakpoint-up(sm) {
        padding: 3em;
      }

    }

  }

}
