@import '../../app/item-renderers/renderer-switcher/renderer-switcher-theme';
@import '../../app/item-renderers/practice-renderer/practice-renderer-theme';
@import "../../app/item-renderers/training-renderer/training-renderer-theme";
@import '../../app/item-renderers/training-item-link/training-item-link-theme';
@import '../../app/item-renderers/renderer-header/renderer-header-theme';

@mixin item-renderers($theme) {

  @include ea-training-renderer($theme);
  @include ea-renderer-switcher($theme);
  @include ea-practice-renderer($theme);
  @include ea-training-item-link($theme);
  @include ea-renderer-header($theme);

}

@mixin item-renderer() {

  width: var(--item-renderer-width);
  height: var(--item-renderer-height);

  vertical-align: top;

  ea-renderer-switcher{
    width: 100%;
    height: 100%;
  }

}
