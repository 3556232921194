@use '@angular/material' as mat;
@mixin ea-module($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  ea-module {

    mat-tab-group {

      @include ea-formation-mat-tabs-group($theme);

    }

    .qcm-group {

      margin-top: 1px;

      > div {
        margin-right: 1px;
      }

      > div:last-child {
        margin-right: 0px;
      }

    }

    .link-card {

      @extend .px-2;
      @extend .py-3;

      background-color: mat.get-color-from-palette($accent, 200) !important;
      color: mat.get-color-from-palette($accent, 700) !important;
      box-shadow: none !important;

      border-radius: 0px !important;

      p{
        @extend .mb-0;
      }

    }

  }

}
