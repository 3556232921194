@mixin extended-types-filters() {

  padding-right: 3px;
  text-transform: uppercase;
  color: white !important;
  font-size: 13px;

  font-weight: bold;

  .mat-chip-ripple{
    display: none;
  }

  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  color: white !important;

  &.formation_module-default{

    background-color: var(--module-color) !important;

  }

  &.formation_diplome-default{

    background-color: var(--diplome-color) !important;

  }

  &.ffa_video_athle_tv-default{
    background-color: var(--video-color-500) !important;
  }

  &.quiz-default{

    background-color: var(--quiz-color-500) !important;

  }

  &.theoretical_content-default{

    background-color: var(--savoir-color-500) !important;

  }

  &.fiche_peda-default,
  &.enchainement_situations-356,
  &.enchainement_situations-357,
  &.seance-default,
  &.cycle-default,
  &.situation_jeunes_juges-default{

    background-color: var(--practive-color-500) !important;

  }

  a{

    display: flex;
    align-items: center;

    .count{
      display: inline-block;
      text-align: center;
      font-size: 10px;
      margin-left: 5px;
      margin-right: 5px;
      font-weight: bold;
    }

  }

}
